<script setup lang="ts">
import { onMounted } from 'vue'

defineLayout({
  label: 'PlayaCares Layout',
})

const isVoixTemplatizer = inject<boolean>('isVoixTemplatizer', false)
const { getCommonLayoutData, playaLayoutMounted, setupHeader } = usePlayaLayout()

if (!isVoixTemplatizer)
  setupHeader('a27adfd1-a837-4704-97c4-5b910df8afc6')

const {
  resorts,
  currentResort,
  footerCompanyMenu,
  footerResourcesMenu,
  footerTermsMenu,
} = await getCommonLayoutData(isVoixTemplatizer, ['brands'])

onMounted(() => {
  if (!isVoixTemplatizer)
    return
  playaLayoutMounted(currentResort)
})

provide('accentColor', { value: '#15467b' })
</script>

<template>
  <div>
    <div
      class="text-grey-darker bg-abs-white blocks-theme"
    >
      <div id="app">
        <div id="portal-destination" transition="fade-transition" />

        <BlocksNavbar
          v-if="!isVoixTemplatizer"
          :current-resort="currentResort?.data"
          :resorts="resorts?.data"
        />
        <slot />

        <PlayaresortsFooter
          v-if="!isVoixTemplatizer"
          :current-resort="currentResort?.data"
          :footer-company-menu="footerCompanyMenu?.data"
          :footer-resources-menu="footerResourcesMenu?.data"
          :footer-terms-menu="footerTermsMenu?.data"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import '~/assets/sass/blocks.scss';
</style>
